import React from 'react'
import NextLink from 'next/link'
import { Link as AscendLink } from '@achieve/ascend'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { ROUTE_EXCEPT_NOFOLLOW, ROUTE_ASSET_EXCEPT } from 'constants/navigation'
import { getProductAndAddDefaultUtmsToLink } from 'utils/queryManipulation'
import Cookies from 'js-cookie'
import { disableClientSideRoutes } from 'constants/experiments'

const IGNORE_LIST = ['slug', 'page', 'categoryname', 'v', 'device']

export const preserveQueryParams = (href, query) => {
  try {
    // href = /get-started?brand=achieve
    const [hrefWithAnchor, hrefQuery] = href.split('?')
    const [hrefOnly, anchorOnly] = hrefWithAnchor.split('#')

    // brand=achieve
    const hrefQueries = new URLSearchParams(hrefQuery)

    // ['utm_params', 'qa-test']
    const routerQueryEntries = new URLSearchParams(query).entries()
    // remove query params added by dynamic routes
    // https://nextjs.org/docs/routing/dynamic-routes#caveats
    const dynamicParamsToIgnore = new Set(IGNORE_LIST)

    for (const [key, value] of routerQueryEntries) {
      if (!dynamicParamsToIgnore.has(key)) {
        // overwrite any queries by the same key
        hrefQueries.set(key, value)
      }
    }

    if (!href.includes('?') && hrefQueries.toString() === '') return href

    // result => /get-started?brand=achieve&utm_params=qa-test#Privacy-Policy
    return `${hrefOnly}?${hrefQueries.toString()}${anchorOnly ? '#' + anchorOnly : ''}`
  } catch (e) {
    // return default href
    return href
  }
}

const LinkWrapper = React.forwardRef(({ children, refAs, ...props }, ref) => {
  if (refAs) {
    props[refAs] = ref
  }

  return (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, props)
        : typeof children === 'string'
        ? children
        : null}
    </>
  )
})

LinkWrapper.displayName = 'LinkWrapper'

/**
 *
 * @param {string} href original href string
 * @param {ReactNode} children child components wrapped with AchieveLink
 * @param {boolean} withNextLink if true use next/link, default use AscendLink
 * @param {RefObject} refAs external ref to be used in the link child
 * @returns {ReactElement} returns link wrapped child component with forwarded
 *                         ref, passed props and transformed/memoized href
 *                         with original query params preserved
 */

const AchieveLink = ({
  href,
  children,
  withNextLink = false,
  refAs,
  track,
  onClick,
  rel,
  noLink,
  noPreserveParams,
  ...restProps
}) => {
  let route = useRouter()
  if (!route.asPath) {
    route = { ...route, query: {}, asPath: '/' }
  }
  let { query = {} } = route || {}

  if (Object.keys(query).length === 0 && route.asPath.includes('?')) {
    const urlParams = new URLSearchParams(route.asPath.split('?')[1])
    for (const [key, value] of urlParams.entries()) {
      query = { ...query, [key]: value }
    }
  }

  const visitId = Cookies.get('eh_visit_id')
  let memoHref = useMemo(
    () => getProductAndAddDefaultUtmsToLink(preserveQueryParams(href, query, visitId)),
    [href, query, visitId]
  )
  if (noPreserveParams) memoHref = href

  const LinkComponent = withNextLink ? NextLink : AscendLink
  const nextProps = withNextLink ? { passHref: true } : {}

  let linkRel = rel ?? null
  const noFollowExceptionRoute = ROUTE_EXCEPT_NOFOLLOW.findIndex(
    (exception) => String(`${href}`).toLowerCase().includes(exception) >= 0
  )
  if (
    String(`${href}`).toLowerCase().includes('http') &&
    noFollowExceptionRoute == -1 &&
    !String(`${href}`).toLowerCase().includes(ROUTE_ASSET_EXCEPT)
  ) {
    if (!String(`${linkRel}`).toLowerCase().includes('nofollow')) {
      linkRel = `${rel ? `${rel} ` : ''}nofollow`
    }
    restProps = { ...restProps, target: '_blank' }
  }

  const moveAnchorRight = (url) => {
    if (!url) {
      return url
    }
    let anchorIndex = url.indexOf('#')
    let queryIndex = url.indexOf('?')
    if (queryIndex > anchorIndex && queryIndex > -1 && anchorIndex > -1) {
      let dividedUrl = url.split('#')
      let anchorAndQuery = dividedUrl[1].split('?')
      return `${dividedUrl[0]}?${anchorAndQuery[1]}#${anchorAndQuery[0]}`
    }
    return url
  }

  memoHref = moveAnchorRight(memoHref)

  if (disableClientSideRoutes.includes(memoHref)) {
    onClick = (e) => {
      e.preventDefault()
      window.location.href = memoHref
    }
  }

  return memoHref?.[0] === '/' || noLink ? (
    <TrackingWrapper
      track={{ href: memoHref, ...track }}
      onClick={onClick}
      withNextLink={withNextLink}
    >
      <LinkComponent
        component={memoHref ? 'a' : 'span'}
        href={memoHref}
        rel={linkRel}
        underline="hover"
        {...restProps}
        {...nextProps}
      >
        {children?.type === 'a' ? children : <LinkWrapper refAs={refAs}>{children}</LinkWrapper>}
      </LinkComponent>
    </TrackingWrapper>
  ) : (
    <LinkComponent
      component={memoHref ? 'a' : 'span'}
      href={memoHref}
      onClick={onClick}
      rel={linkRel}
      underline="hover"
      {...restProps}
      {...nextProps}
    >
      {children?.type === 'a' ? children : <LinkWrapper refAs={refAs}>{children}</LinkWrapper>}
    </LinkComponent>
  )
}

export { AchieveLink }
